import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import {
  animationAgricultureText,
  animationAgricultureTitle,
  animationInvestorText,
  animationInvestorTitle,
} from "../../../../constants/step";
import * as brownAnimation from "../../../../lottie/fe-brown-animation.json";
import * as greenAnimation from "../../../../lottie/fe-green-animation.json";
import * as yellowAnimation from "../../../../lottie/fe-yellow-animation.json";
import { AnimationData } from "../../../../types/animation";
import "./animation.css";

type Props = {
  interval?: number;
  selectedIndustry?: string;
  nextStepCallback: () => void;
};

const Animation = ({
  interval = 5000,
  selectedIndustry,
  nextStepCallback,
}: Props) => {
  const [currentTitle, setCurrentTitle] = useState<string>("");
  const [currentText, setCurrentText] = useState<string>("");
  const [showImage, setShowImage] = useState<boolean>(false);
  const [animationData, setAnimationData] = useState<AnimationData>(
    yellowAnimation as AnimationData
  );
  const [animationClass, setAnimationClass] = useState<string>("fade-in");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const height = isMobile ? 200 : 300;
  const width = isMobile ? 350 : 600;

  useEffect(() => {
    let title: string = "";
    let texts: string[] = [];
    const animations = [yellowAnimation, greenAnimation, brownAnimation];

    switch (selectedIndustry) {
      case "Farmers":
        title = animationAgricultureTitle;
        texts = animationAgricultureText;
        break;
      case "Investors":
        title = animationInvestorTitle;
        texts = animationInvestorText;
        break;
      default:
        title = animationAgricultureTitle;
        texts = animationAgricultureText;
    }

    setCurrentTitle(title);

    const updateState = (index: number) => {
      setAnimationClass("fade-out");
      setTimeout(() => {
        setAnimationData(animations[index] as AnimationData);
        setCurrentText(texts[index]);
        setAnimationClass("fade-in");
      }, 500);
    };

    const intervals = texts.map((_, index) =>
      setTimeout(() => {
        updateState(index);
        if (index === texts.length - 1) {
          setTimeout(() => setShowImage(true), interval);
        }
      }, index * interval)
    );

    return () => intervals.forEach(clearTimeout);
  }, [selectedIndustry, interval]);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  let subtitleColor = "#ffff";
  switch (animationData) {
    case yellowAnimation:
      subtitleColor = "#FFAD01";
      break;
    case greenAnimation:
      subtitleColor = "#225D27";
      break;
    case brownAnimation:
      subtitleColor = "#633E28";
      break;
  }

  return (
    <div className="animation-main">
      {!showImage && (
        <>
          <p className={`lottie-title`}> {currentTitle}</p>
          <div className={`lottie-animation ${animationClass}`}>
            <Lottie
              options={lottieOptions}
              height={height}
              width={width}
              speed={0.5}
            />
          </div>
          <p
            style={{ color: subtitleColor }}
            className={`lottie-text ${animationClass}`}
          >
            {currentText}
          </p>
        </>
      )}
      {showImage && (
        <div className="logo-and-text">
          <img
            src={"/emptyLogo.svg"}
            alt="freshearth"
            height={height}
            width={width}
          />
          <p>Fresh Earth Exchange</p>
          <button type="submit" onClick={nextStepCallback}>
            Let’s grow together
          </button>
        </div>
      )}
    </div>
  );
};

export default Animation;
