import { useMediaQuery, useTheme } from "@mui/material";
import Spline from "@splinetool/react-spline";
import { Application } from "@splinetool/runtime";
import { useCallback, useEffect, useRef } from "react";

import "./index.css";

export type Props = {
  hidden?: boolean;
  scaleFactor?: number;
  top?: number;
  left?: number;
  opacity?: number;
};

const PlanetLayer = ({
  hidden = false,
  scaleFactor = 1.5,
  top = 50,
  left = 50,
  opacity = 1,
}: Props) => {
  const splineAppRef = useRef<Application | null>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const canvasFactor = 0.7;

  const mainWidth = isMobile ? 250 : 332;
  const mainHeight = isMobile ? 250 : 331;
  const scaledHeight = mainWidth * scaleFactor * canvasFactor;
  const scaledWidth = mainHeight * scaleFactor * canvasFactor;

  const applyScaleFactor = useCallback(
    (splineApp: Application) => {
      const planet = splineApp.findObjectByName("Universe");

      if (planet) {
        const factor = 0.4 * scaleFactor;
        planet.scale.x = factor;
        planet.scale.y = factor;
        planet.scale.z = factor;
      }
    },
    [scaleFactor]
  );

  const onLoad = (splineApp: Application) => {
    splineAppRef.current = splineApp;
    applyScaleFactor(splineApp);

    if (!canvasRef.current) return;

    const canvas = canvasRef.current.querySelector("canvas");
    if (!canvas) return;

    canvas.style.width = `${scaledWidth}px`;
    canvas.style.height = `${scaledHeight}px`;
  };

  useEffect(() => {
    if (splineAppRef.current) {
      applyScaleFactor(splineAppRef.current);

      if (!canvasRef.current) return;

      const canvas = canvasRef.current.querySelector("canvas");
      if (!canvas) return;

      canvas.style.width = `${scaledWidth}px`;
      canvas.style.height = `${scaledHeight}px`;
    }
  }, [applyScaleFactor, scaledHeight, scaledWidth]);

  if (hidden) return <></>;

  return (
    <div
      className="planet-spline-layer"
      ref={canvasRef}
      style={{
        top: `${top}%`,
        left: `${left}%`,
        height: scaledHeight,
        width: scaledWidth,
        opacity: opacity,
      }}
    >
      <Spline scene="./planet.spline" onLoad={onLoad} />
    </div>
  );
};

export default PlanetLayer;
