import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "../../components/snackbar/snackbar";
import { createContactEmailBody } from "../../utils/email-helper";

import "./contact.css";

export type ContactInformation = {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
};

type ContactInformationErrors = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

const Contact = () => {
  const [info, setInfo] = useState<ContactInformation>({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<ContactInformationErrors>({});
  const [showErrSnack, setShowErrSnack] = useState<boolean>(false);
  const [showSuccess, setShowSuncces] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hash = window.location.hash;
  const token = hash.startsWith("#") ? hash.substring(1) : undefined;

  const route = token ? `/#${token}` : "/";

  const handleSend = (): void => {
    if (isLoading) return;
    if (!validate()) return;

    const apiUrl = `${process.env.REACT_APP_MC_URL}/api/sendsupportmail`;
    const recipient = process.env.REACT_APP_CONTACT_FORM_EMAIL_RECEIVER;

    setIsLoading(true);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipient,
        subject: `Message from ${info.firstName} ${info.lastName} via FreshEarth Website Contact Form`,
        body: createContactEmailBody(info, state),
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          setShowSuncces(true);
          return;
        }

        setShowErrSnack(true);
      })
      .catch(() => setShowErrSnack(true))
      .finally(() => setIsLoading(false));
  };

  const validate = (): boolean => {
    const validationErrors: ContactInformationErrors = {};

    if (!info.firstName) {
      validationErrors.firstName = "First name is required.";
    }

    if (!info.lastName) {
      validationErrors.lastName = "Last name is required.";
    }

    if (!info.email) {
      validationErrors.email = "Email is required.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(info.email)) {
        validationErrors.email = "Not a valid email address.";
      }
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const propName = event.target.name;
    const propValue = event.target.value;

    setInfo((prev) => ({
      ...prev,
      [propName]: propValue,
    }));

    setErrors((prev) => ({
      ...prev,
      [propName]: undefined,
    }));
  };

  return (
    <>
      <Snackbar
        open={showErrSnack}
        message={"Something went wrong."}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        variant="error"
        onClose={() => setShowErrSnack(false)}
      />
      {showSuccess && (
        <div className="contact-main-cont" style={{ textAlign: "center" }}>
          <CheckCircleIcon
            sx={{ color: "green", width: "5rem", height: "5rem", mb: 2 }}
          />
          <strong>{`Thank you, ${info.firstName}!`}</strong>
          <span>Your message has been successfully submitted.</span>
          <div className="back-btn no-select" onClick={() => navigate("/")}>
            <ArrowBackIcon />
          </div>
        </div>
      )}
      {!showSuccess && (
        <div className="contact-main-cont">
          <div className="top-left-icon">
            <img
              src={"/backArrow.svg"}
              alt="freshearth"
              onClick={() => navigate(route)}
            />
          </div>
          <p className="contact-title">Contact</p>
          <div className="contact-input-box">
            <label>First Name *</label>
            <input
              name="firstName"
              value={info.firstName}
              onChange={handleInputChange}
            />
            <span className="contact-err-text" hidden={!errors.firstName}>
              {errors.firstName}
            </span>
          </div>

          <div className="contact-input-box">
            <label>Last Name *</label>
            <input
              name="lastName"
              value={info.lastName}
              onChange={handleInputChange}
            />
            <span className="contact-err-text" hidden={!errors.lastName}>
              {errors.lastName}
            </span>
          </div>

          <div className="contact-input-box">
            <label>Email *</label>
            <input
              name="email"
              value={info.email}
              onChange={handleInputChange}
            />
            <span className="contact-err-text" hidden={!errors.email}>
              {errors.email}
            </span>
          </div>

          <div className="contact-input-box">
            <label>Message</label>
            <textarea
              rows={8}
              name="message"
              value={info.message}
              onChange={handleInputChange}
            />
          </div>
          <div className="contact-btn no-select" onClick={handleSend}>
            {isLoading ? (
              <CircularProgress sx={{ mr: 1, color: "white" }} size={25} />
            ) : (
              "Send"
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
