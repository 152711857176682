import { useState } from "react";
import { Steps } from "../../../types/general";

export const useStepNavigation = () => {
  const [step, setStep] = useState<Steps>(Steps.Landing);
  const [previousStep, setPreviousStep] = useState<Steps | null>(null);

  const goToStep = (newStep: Steps) => {
    setPreviousStep(step);
    setStep(newStep);
  };

  const goToPrevStep = () => {
    if (previousStep === null) return;
    if (previousStep === Steps.Animation) {
      setStep(Steps.Roles);
      setPreviousStep(Steps.Roles - 1);
      return;
    }

    setStep(previousStep);
    setPreviousStep(
      previousStep !== Steps.Landing ? previousStep - 1 : Steps.Landing
    );
  };

  const goToInitialStep = () => {
    setPreviousStep(step);
    setStep(Steps.Landing);
  };

  const goToNextStep = () => {
    setPreviousStep(step);
    setStep((prev) => prev + 1);
  };

  const goToAnimation = () => {
    setPreviousStep(step);
    setStep(Steps.Animation);
  };

  return {
    step,
    previousStep,
    goToStep,
    goToNextStep,
    goToPrevStep,
    goToInitialStep,
    goToAnimation,
  };
};
