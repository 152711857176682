import { Box, Typography } from "@mui/material";
import { infoCardDetails } from "../../constants/general";
import { NavigationButton } from "../../types/general";

import { useTokenInfo } from "../../features/button-tree/hooks/use-token-info";
import useBreakpoint from "../../hooks/use-breakpoint";

import "./index.css";

type Props = {
  buttonClicked: NavigationButton;
  onCloseCallback: () => void;
  goToAnimation?: (variant: string) => void;
};

const InfoCard = ({ onCloseCallback, buttonClicked, goToAnimation }: Props) => {
  const isMobile = useBreakpoint("lg");
  const { info } = useTokenInfo();

  const registerUrl = `${process.env.REACT_APP_EXTERNAL_LINK}/register`;

  const infoCardDetail = infoCardDetails[buttonClicked.text];

  const handleButtonClick = (): void => {
    if (info && buttonClicked.text === "Farmers") {
      window.location.href = registerUrl;
      return;
    }

    if (!goToAnimation) return;

    goToAnimation(buttonClicked.text);
  };

  const parseDescription = (description: string) => {
    const parts = description.split(/(\*\*.*?\*\*)/g);

    return parts.map((part, index) =>
      part.startsWith("**") && part.endsWith("**") ? (
        <Typography
          key={index}
          component="span"
          color="#FFAD01"
          fontWeight={700}
        >
          {part.slice(2, -2)}
        </Typography>
      ) : (
        part
      )
    );
  };

  return (
    <Box>
      {isMobile && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            cursor: "pointer",
          }}
          onClick={onCloseCallback}
        >
          <img src="./close-icon.png" alt="" />
        </Box>
      )}
      <div className="info-card-main-cont">
        <div className="info-card-cont">
          <Typography fontFamily="Comfortaa" fontSize={24}>
            {infoCardDetail?.title}
          </Typography>
          <Typography
            fontFamily="Open Sans"
            fontSize={16}
            fontWeight={400}
            letterSpacing={0.25}
            textAlign="left"
          >
            {parseDescription(infoCardDetail?.description)}
          </Typography>
          <div className="info-card-btn no-select" onClick={handleButtonClick}>
            {info && buttonClicked.text === "Farmers" ? "Register" : "Explore"}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default InfoCard;
