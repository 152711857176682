import { useState } from "react";
import { NavButton } from "../../../components/buttons/nav/nav-button";
import InfoCard from "../../../components/info-card/info-card";
import { Steps } from "../../../types/general";

import { BackButton } from "../../../components/buttons/back/back-button";
import useBreakpoint from "../../../hooks/use-breakpoint";
import { NavigationButton } from "../../../types/general";

import "./step.css";

type Props = {
  stepType: Steps;
  buttons: NavigationButton[];
  goToAnimation?: (variant: string) => void;
  prevStepCallback: () => void;
  nextStepCallback: () => void;
};

const Step = ({
  stepType,
  buttons,
  nextStepCallback,
  goToAnimation,
  prevStepCallback,
}: Props) => {
  const isMobile = useBreakpoint("lg");
  const [clickedButton, setClickedButton] = useState<NavigationButton>();

  const handleNextStep = (buttonClicked: NavigationButton): void => {
    if (buttonClicked.commingSoon) return;
    if (!buttonClicked.commingSoon && stepType !== Steps.Roles) {
      nextStepCallback();
      return;
    }

    if (!buttonClicked.commingSoon) {
      setClickedButton(buttonClicked);
    }
    setClickedButton(buttonClicked);
  };

  const handlePrevStep = () => {
    setClickedButton(undefined);
    prevStepCallback();
  };

  const onHoverCardClose = () => {
    setClickedButton(undefined);
  };

  return (
    <>
      {((stepType !== Steps.Landing && !clickedButton) || !isMobile) && (
        <BackButton prevStepCallback={handlePrevStep} />
      )}

      {((!clickedButton && isMobile) || !isMobile) && (
        <div className="steps-cont">
          <div className={`steps-content`}>
            {buttons.map((btn) => (
              <NavButton
                key={btn.text}
                button={btn}
                onClickCallback={() => handleNextStep(btn)}
              />
            ))}
          </div>
        </div>
      )}

      {clickedButton && (
        <InfoCard
          key={clickedButton?.text}
          buttonClicked={clickedButton}
          onCloseCallback={onHoverCardClose}
          goToAnimation={goToAnimation}
        />
      )}
    </>
  );
};

export default Step;
