import { Route, Routes } from "react-router-dom";
import StarsLayer from "./components/layers/stars/stars-layer";
import ButtonTree from "./features/button-tree/button-tree";
import Contact from "./features/contact-form/contact";

import "./App.css";

const App = () => {
  return (
    <StarsLayer>
      <Routes>
        <Route path="/contact/:token?" element={<Contact />} />
        <Route path="/:token?" element={<ButtonTree />} />
        <Route path="*" element={<ButtonTree />} />
      </Routes>
    </StarsLayer>
  );
};

export default App;
