import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ConnectButton from "../../../../components/buttons/connect/connect-button";

import useBreakpoint from "../../../../hooks/use-breakpoint";
import "./index.css";

type MainProps = {
  nextStepCallback: () => void;
};

const LandingStep = ({ nextStepCallback }: MainProps) => {
  const isMobile = useBreakpoint();
  const animationDuration = 500; // Match the duration of the fade-out animation in css
  const autoCloseDuration = 17000; // Duration to automatically close the text

  const [animate, setAnimate] = useState<boolean>(false);
  const [showLeftHT, setShowLeftHt] = useState<boolean>(false);
  const [hoverClassLeft, setHoverClassLeft] = useState<string>("");
  const [showRightHT, setShowRightHt] = useState<boolean>(false);
  const [hoverClassRight, setHoverClassRight] = useState<string>("");

  const [leftTimeoutId, setLeftTimeoutId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [rightTimeoutId, setRightTimeoutId] = useState<NodeJS.Timeout | null>(
    null
  );

  const leftHTRef = useRef<HTMLDivElement>(null);
  const rightHTRef = useRef<HTMLDivElement>(null);

  const handleLeftClick = () => {
    if (isMobile) return;

    if (showLeftHT) {
      setHoverClassLeft("fade-out-left");
      setTimeout(() => {
        setShowLeftHt(false);
      }, animationDuration);
      if (leftTimeoutId) clearTimeout(leftTimeoutId);
    } else {
      setHoverClassLeft("fade-in-left");
      setShowLeftHt(true);
      const timeoutId = setTimeout(() => {
        setHoverClassLeft("fade-out-left");
        setTimeout(() => {
          setShowLeftHt(false);
        }, animationDuration);
      }, autoCloseDuration);
      setLeftTimeoutId(timeoutId);
    }
  };

  const handleRightClick = () => {
    if (isMobile) return;

    if (showRightHT) {
      setHoverClassRight("fade-out-right");
      setTimeout(() => {
        setShowRightHt(false);
      }, animationDuration);
      if (rightTimeoutId) clearTimeout(rightTimeoutId);
    } else {
      setHoverClassRight("fade-in-right");
      setShowRightHt(true);
      const timeoutId = setTimeout(() => {
        setHoverClassRight("fade-out-right");
        setTimeout(() => {
          setShowRightHt(false);
        }, animationDuration);
      }, autoCloseDuration);
      setRightTimeoutId(timeoutId);
    }
  };

  const handleClick = () => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
      nextStepCallback();
    }, 800);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        showLeftHT &&
        leftHTRef.current &&
        !leftHTRef.current.contains(event.target as Node)
      ) {
        setHoverClassLeft("fade-out-left");
        setTimeout(() => {
          setShowLeftHt(false);
        }, animationDuration);
      }
      if (
        showRightHT &&
        rightHTRef.current &&
        !rightHTRef.current.contains(event.target as Node)
      ) {
        setHoverClassRight("fade-out-right");
        setTimeout(() => {
          setShowRightHt(false);
        }, animationDuration);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showLeftHT, showRightHT, animationDuration]);

  return (
    <>
      <Box
        className={`main-step-content ${animate ? "animate" : ""}`}
        sx={{ ml: { lg: "60px" }, mt: { xs: "35px", lg: "-10px" } }}
      >
        <div
          className={`text left ${showLeftHT && "hide"} ${
            animate ? "fade-out-left" : ""
          } no-select`}
        >
          <p>
            <span className="main-step-span white">YEAR 2100: </span>
            <span className="main-step-span orange" onClick={handleLeftClick}>
              11 BILLION PEOPLE
            </span>
          </p>
        </div>
        <ConnectButton onClick={handleClick} />
        <div
          className={`text right ${showRightHT && "hide"}${
            animate ? "fade-out-right" : ""
          } no-select`}
        >
          <p>
            <span className="main-step-span white">TOTAL LAND: </span>
            <span className="main-step-span orange" onClick={handleRightClick}>
              11 BILLION HECTARES
            </span>
          </p>
        </div>
      </Box>
      {showLeftHT && !isMobile && (
        <div
          ref={leftHTRef}
          className={`main-step-hover-text left ${hoverClassLeft}`}
        >
          <p>
            The United Nations' World Population Prospects 2022 suggests that
            the world population might reach 10.4 billion in the mid-2080s and
            then stabilize or grow slightly to around 10.9 billion by the end of
            the century​ (United Nations)​. These projections highlight the
            significant uncertainties in predicting future demographic trends
            and the impact of socio-economic and environmental factors on
            population growth.
          </p>
        </div>
      )}
      {showRightHT && !isMobile && (
        <div
          ref={rightHTRef}
          className={`main-step-hover-text right ${hoverClassRight}`}
        >
          <p>
            The global biologically productive space totals approximately 11.4
            billion hectares. This space includes areas capable of supporting
            photosynthesis at sufficient rates to provide economically useful
            concentrations of biomass. Marginal and unproductive regions, such
            as deserts, tundra, and the deep oceans, are excluded (Wackernagel &
            Monfreda, 2004).
          </p>
        </div>
      )}
    </>
  );
};

export default LandingStep;
