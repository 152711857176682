import AgricultureForm from "./forms/AgricultureForm";
import { InvestorForm } from "./forms/InvestorForm";

type StepProps = {
  selectedIndustry?: string;
  backToLandingCallback?: () => void;
};

const Questionnaire = ({
  selectedIndustry,
  backToLandingCallback,
}: StepProps) => {
  switch (selectedIndustry) {
    case "Farmers":
      return <AgricultureForm backToLandingCallback={backToLandingCallback} />;
    case "Investors":
      return <InvestorForm />;
    default:
      return <></>;
  }
};

export default Questionnaire;
