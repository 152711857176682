import { Box, Typography } from "@mui/material";
import useBreakpoint from "../../../hooks/use-breakpoint";

export const ImpactAndVision = () => {
  const isMobile = useBreakpoint();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: { xs: 3, md: 5 },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "32px", md: "48px" },
          fontWeight: { xs: 500, md: 700 },
          fontFamily: "Comfortaa",
        }}
      >
        Impact & Vision
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: { xs: 5, md: 10 },
          alignItems: "center",
        }}
      >
        <img
          src="/impact-vision.png"
          alt="impact"
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            ...(!isMobile && {
              width: "auto",
              height: "auto",
            }),
          }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Open Sans",
            width: { sm: "auto", xl: "587px" },
          }}
        >
          Fresh Earth allows our users to measure, verify, and validate every
          action with transparent data integrity, ensuring trust and scientific
          rigor. By merging local resources, global investment, and
          expert-driven guidance into a seamless, digital framework, we empower
          profitable, regenerative land use at scale.
          <br />
          <br />
          Our vision is a connected, circular economy of farmers, experts,
          suppliers, and impact investors—each playing a part in producing
          higher-quality food, boosting biodiversity, and generating verified
          carbon credits. Through minimal fees, shared knowledge, and a robust
          technological backbone, we create a swift, low-cost path to
          sustainable outcomes—restoring land, reducing costs, and delivering
          maximum benefits for all.
        </Typography>
      </Box>
    </Box>
  );
};
