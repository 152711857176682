export const animationAgricultureTitle = "Transition to regenerative farming";
export const animationAgricultureText = [
  "Get paid more & paid up front",
  "Simple & safe steps",
  "Reliable science & compliance",
];
export const animationInvestorTitle = "Invest in Regeneration";
export const animationInvestorText = [
  "A new, trusted compliance layer",
  "Proven Science & Open Information",
  "Safety and assurance",
];
