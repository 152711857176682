import { useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import LogoLayer from "../../components/layers/logo/logo-layer";
import PlanetLayer, {
  Props,
} from "../../components/layers/planet/planet-layer";
import { industryButtons, roleButtons } from "../../constants/general";
import useBreakpoint from "../../hooks/use-breakpoint";
import { Steps } from "../../types/general";
import { useStepNavigation } from "./hooks/use-step-navigation";
import Animation from "./steps/animation/animation";
import LandingStep from "./steps/landing/landing-step";
import Questionnaire from "./steps/questionnaire/Questionnaire";
import Step from "./steps/step";

import { Box } from "@mui/material";

import { BackButton } from "../../components/buttons/back/back-button";
import { AboutLayer } from "../../components/layers/about/about-layer";

import { About } from "../about/about";
import "./index.css";

const planetPropsConfig = (isMobile: boolean, step: Steps): Props =>
  step === Steps.Landing
    ? { scaleFactor: 2 }
    : {
        scaleFactor: 2,
        top: step === Steps.Questionnaire ? 50 : isMobile ? 20 : 40,
        opacity: step === Steps.Animation ? 0 : 1,
      };

const ButtonTree = () => {
  const isMobile = useBreakpoint();

  const [animationVariant, setAnimationVariant] = useState<string>();

  const {
    step,
    goToStep,
    goToNextStep,
    goToPrevStep,
    goToInitialStep,
    goToAnimation,
  } = useStepNavigation();

  const hideLayers =
    (step === Steps.Landing && !isMobile) ||
    step === Steps.Animation ||
    step === Steps.About;

  const planetProps = useMemo(
    () => planetPropsConfig(isMobile, step),
    [isMobile, step]
  );

  const handleAnimation = (variant: string) => {
    setAnimationVariant(variant);
    goToAnimation();
  };

  const renderStep = (step: Steps) => {
    const stepComponents = {
      [Steps.Landing]: <LandingStep nextStepCallback={goToNextStep} />,
      [Steps.Industry]: (
        <Step
          stepType={Steps.Industry}
          buttons={industryButtons}
          nextStepCallback={goToNextStep}
          prevStepCallback={goToPrevStep}
        />
      ),
      [Steps.Roles]: (
        <Step
          stepType={Steps.Roles}
          buttons={roleButtons}
          nextStepCallback={goToNextStep}
          prevStepCallback={goToPrevStep}
          goToAnimation={handleAnimation}
        />
      ),
      [Steps.Animation]: (
        <Animation
          selectedIndustry={animationVariant}
          nextStepCallback={goToNextStep}
        />
      ),
      [Steps.Questionnaire]: (
        <>
          <BackButton prevStepCallback={goToPrevStep} />
          <Questionnaire
            selectedIndustry={animationVariant}
            backToLandingCallback={goToInitialStep}
          />
        </>
      ),
      [Steps.About]: <About prevStepCallback={goToPrevStep} />,
    };

    return stepComponents[step] || null;
  };

  return (
    <>
      <PlanetLayer {...planetProps} />
      <LogoLayer
        position={isMobile ? "bottom" : "top"}
        hidden={isMobile ? step !== Steps.Landing : hideLayers}
        backToLandingCallback={goToInitialStep}
      />
      <AboutLayer hidden={step !== Steps.Roles} goToStepCallback={goToStep} />
      <TransitionGroup>
        <CSSTransition key={step} classNames="fade" timeout={300}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100%",
            }}
          >
            {renderStep(step)}
          </Box>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default ButtonTree;
