import { Box, Typography } from "@mui/material";
import { NavigationButton } from "../../../types/general";

import "./index.css";

type Props = {
  button: NavigationButton;
  onClickCallback: () => void;
};

export const NavButton = ({ button, onClickCallback }: Props) => {
  const enabled = !button.commingSoon;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        className={`nav-button ${enabled && "clicked"} no-select`}
        sx={{ cursor: enabled ? "pointer" : "auto" }}
        onClick={onClickCallback}
      >
        {button.text}
      </Box>
      <Typography
        sx={{
          color: "#FFFFFF",
          fontSize: 12,
          fontWeight: 600,
          fontFamily: "Open Sans",
          opacity: enabled ? "0%" : "50%",
        }}
      >
        Coming soon!
      </Typography>
    </Box>
  );
};
