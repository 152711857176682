import { Box, Typography } from "@mui/material";
import { useState } from "react";

const Button = ({
  text,
  onClickCallback,
}: {
  text: string;
  onClickCallback: () => void;
}) => {
  const [line1, line2] = text.split(" ");
  const size = 400;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "50%",
        width: { xs: size - 200, md: size - 150, lg: size },
        height: { xs: size - 200, md: size - 150, lg: size },
        border: "2px solid #CB7F00",
        backgroundColor: "#38260036",
        backdropFilter: "blur(4px)",
        fontSize: { xs: 22, md: 28, lg: 32 },
        fontWeight: 600,
        lineHeight: 1.5,
        textAlign: "center",
        color: "white",
        cursor: "pointer",
      }}
      onClick={onClickCallback}
    >
      <span>{line1}</span>
      <span>{line2}</span>
    </Box>
  );
};

export const InvestorForm = () => {
  const [clicked, setClicked] = useState<boolean>(false);

  const handleClick = () => {
    setClicked(true);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mb: { xs: 12, sm: 0 },
      }}
    >
      {!clicked && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: "10%",
            width: "100%",
            height: "100%",
          }}
        >
          <Button text="Token Investment" onClickCallback={handleClick} />
          <Button text="Portfolio Investment" onClickCallback={handleClick} />
        </Box>
      )}
      {clicked && (
        <Box
          sx={{
            width: { xs: "90%", sm: 400 },
            height: 400,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,

            borderRadius: "48px",
            border: "1px solid #CB7F00",
            backgroundColor: "#38260036",
            backdropFilter: "blur(4px)",
          }}
        >
          <Typography fontSize={21} fontWeight={600} color="white">
            Please enter code
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <input
                key={index}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 5,
                  border: "none",
                }}
              />
            ))}
          </Box>
          <Box
            sx={{
              borderRadius: "80px",
              py: "9px",
              px: "23px",
              backgroundColor: "#1E6F30",
              fontFamily: "Comfortaa",
              color: "white",
              fontWeight: 700,
              fontSize: 14,
              cursor: "pointer",
            }}
          >
            APPLY
          </Box>
        </Box>
      )}
    </Box>
  );
};
