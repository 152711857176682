import { Box, Typography } from "@mui/material";
import useBreakpoint from "../../../hooks/use-breakpoint";

type Props = {
  prevStepCallback: () => void;
};

export const BackButton = ({ prevStepCallback }: Props) => {
  const isMobile = useBreakpoint();
  return (
    <Box
      className="no-select"
      sx={{
        position: "absolute",
        top: { xs: 20, sm: 50 },
        left: { xs: 20, sm: 50 },
        cursor: "pointer",
      }}
      onClick={prevStepCallback}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Box
          sx={{
            border: "1px solid #CB7F00",
            borderRadius: "50%",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt="back"
            src={"/back-icon.svg"}
            width={isMobile ? 12 : 20}
            height={isMobile ? 12 : 20}
          />
        </Box>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: 18, sm: 25 },
            color: "white",
            fontFamily: "Open Sans",
          }}
        >
          Back
        </Typography>
      </Box>
    </Box>
  );
};
