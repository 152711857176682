import { useMediaQuery, useTheme } from "@mui/material";

/**
 * Custom hook to determine if the current screen size matches the specified breakpoint.
 * @param {string} breakpoint - The breakpoint to check (default is "sm").
 * @returns {boolean} - True if the screen size matches the breakpoint, false otherwise.
 */
const useBreakpoint = (breakpoint: "xs" | "sm" | "md" | "lg" | "xl" = "sm") => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
};

export default useBreakpoint;
