import { NavigationButton } from "../types/general";

export const industryButtons: NavigationButton[] = [
  { text: "Health", commingSoon: true },
  { text: "Plastics", commingSoon: true },
  { text: "Agriculture", commingSoon: false },
  { text: "Electricity", commingSoon: true },
  { text: "Food", commingSoon: true },
];

export const roleButtons: NavigationButton[] = [
  { text: "Farmers", commingSoon: false },
  { text: "Agronomists", commingSoon: true },
  { text: "Suppliers", commingSoon: true },
  { text: "Investors", commingSoon: false },
  { text: "Research", commingSoon: true },
];

// Use ** <text> ** for highlighted words.
export const infoCardDetails: Record<
  string,
  { title: string; description: string }
> = {
  Farmers: {
    title: "The Agriculture Industry",
    description:
      "Global agricultural land use is 5 billion hectares, causing biodiversity loss, water waste, soil contamination, and up to 37% of global emissions. Regenerative practices like polycultures, drip irrigation, and no-till farming enhance biodiversity, water efficiency, and carbon sequestration.",
  },
  Investors: {
    title: "The Impact Economy",
    description:
      "Global ESG and impact investments surpassed **$35 trillion in 2022**, reflecting the demand for verifiable, high-value solutions in sustainability. Proven impact, particularly in regenerative practices, drives value in CSR initiatives and positions impact as a profitable asset class.",
  },
};
