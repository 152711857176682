import { Box, Typography } from "@mui/material";
import { members } from "../../../constants/about";
import useBreakpoint from "../../../hooks/use-breakpoint";
import { TeamMember } from "../../../types/about";

const Member = ({ img, name, title }: TeamMember) => {
  const isMobile = useBreakpoint();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        textAlign: "center",
        gap: 1,
        width: { xs: 130, md: 233 },
      }}
    >
      <img
        alt="member"
        src={img}
        style={{
          width: 92,
          height: "auto",
          objectFit: "cover",
          ...(!isMobile && {
            width: "auto",
            height: "auto",
          }),
        }}
      />
      <Typography
        sx={{
          fontFamily: "Open Sans",
          fontWeight: "600",
          fontSize: { xs: 16, md: 24 },
          color: "#212121",
        }}
      >
        {name}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Open Sans",
          fontWeight: "600",
          fontSize: { xs: 12, md: 18 },
          color: "#5B5B5B",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export const Team = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        mb: { xs: 20, md: 0 },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "32px", md: "48px" },
          fontWeight: 700,
          fontFamily: "Comfortaa",
        }}
      >
        Team
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: { xs: "20px", md: "60px" },
          flexWrap: "wrap",
          justifyContent: { xs: "space-around", md: "center" },
        }}
      >
        {members.map((member, index) => (
          <Member
            key={index}
            img={`/${member.img}`}
            name={member.name}
            title={member.title}
          />
        ))}
      </Box>
    </Box>
  );
};
