export type NavigationButton = {
  text: string;
  commingSoon: boolean;
};

export enum Steps {
  Landing,
  Industry,
  Roles,
  Animation,
  Questionnaire,
  About,
}

export type Info =
  | {
      token: string;
      email: string;
      industry: string;
      industryType: string;
      industrySubType: string;
    }
  | undefined;
