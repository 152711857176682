import { jwtDecode } from "jwt-decode";
import { useEffect, useMemo, useState } from "react";
import { Info } from "../../../types/general";

export const useTokenInfo = () => {
  const hash = window.location.hash;
  const token = useMemo(
    () => (hash.startsWith("#") ? hash.substring(1) : undefined),
    [hash]
  );
  const [info, setInfo] = useState<Info | undefined>(undefined);

  useEffect(() => {
    if (!token) {
      setInfo(undefined);
      return;
    }

    try {
      const decodedInfo = jwtDecode(token.substring(1)) as any;
      setInfo(decodedInfo?.data);
    } catch (error) {
      setInfo(undefined);
    }
  }, [token]);

  return { info, token };
};
