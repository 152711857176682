import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

type LogoLayerProps = {
  hidden?: boolean;
  position?: "top" | "bottom";
  width?: string;
  margin?: string;
  backToLandingCallback?: () => void;
};

const LogoLayer = ({
  hidden = false,
  position = "bottom",
  margin = "2%",
  width = "200px",
  backToLandingCallback,
}: LogoLayerProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const hash = window.location.hash;
  const token = hash.startsWith("#") ? hash.substring(1) : undefined;
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const route = token ? `/#${token}` : "/";

  return (
    <img
      src="/logo.png"
      alt="Logo"
      className="logo-main"
      style={{
        position: "absolute",
        top: position === "top" ? 0 : "auto",
        bottom: position === "bottom" ? 0 : "auto",
        left: "50%",
        transform: "translateX(-50%)",
        display: hidden ? "none" : "block",
        width: isMobile ? "124px" : width,
        marginBottom: position === "bottom" ? margin : undefined,
        marginTop: position === "top" ? margin : undefined,
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(route);
        backToLandingCallback && backToLandingCallback();
      }}
    />
  );
};

export default LogoLayer;
